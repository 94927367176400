import { baseRequest } from './index';

/**
 * 线索管理列表
 * @params data Object
 * @returns MenusInfo
*/
export const clueList = (data, aid) => baseRequest.post('/crmapp/clue/list', data, {
  headers: { aid }
});

/**
 * 线索详情
 * @params id int
 * @returns UserInfo
*/
export const clueDetail = (id) => baseRequest.post('/crmapp/clue/detail', {id});

/**
 * 线索跟进保存
 * @params data Object
 * @returns MenusInfo
*/
export const clueTrackSave = (data) => baseRequest.post('/crmapp/clue/track/save', data);

/**
 * 线索转移
 * @params data Object
 * @returns MenusInfo
*/
export const clueTransfer = (data) => baseRequest.post('/crmapp/clue/transfer/save', data);

/**
 * crm1.7.0.1线索查看记录
 * @param urlid
 * @returns {clueOpperLog}
 */

export const clueOpperLog = (clueId) => baseRequest.post('/crmapp/clue/createClueOpperLog', {clueId});

