<template>
  <div class="section">
    <cust-header class="header" :data="contactsDetail"></cust-header >
    <tab-composite class="main"  ref="tabComposite"  fixedHeight="168"  :needBtn="needBtn" :tabList="tabList"  :loadEnable="loadEnable"  :refreshEnable="loadEnable"  :needBorder="false"  @getIndex="getIndex" >
      <ul slot="1"  class="list-wrapper">
        <contacts-info ref="clueInfo"></contacts-info>
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button  class="btn btns" @click="toAddFollow">编辑</cube-button>
    </div>
  </div>

</template>

<script >
import tabComposite from '_c/tab-composite';
import custHeader from '_c/cust-header';
import contactsInfo from '@/views/contacts/components/contacts-info';
import { findValueByKey } from '@/lib/until';
import { clueDetail } from '_api/clue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      contactsDetail: {},
      trackInfoDetailList: [{}],
      tabList: [{
        label: '联系人信息',
        value: 0
      }],
      sellUser: {},
      index: 0,
      needBtn: true,
      loadEnable: false
    };
  },
  computed: {
    ...mapGetters(['dictList'])
  },
  methods: {
    getDetail() {
      clueDetail(this.$route.params.clueId).then((res) => {
        if (res.flag) {
          // 头部信息
          let contactsDetail = res.data.contactsDetail;
          this.contactsDetail = {
            title: contactsDetail.clueName,
            status: findValueByKey(this.dictList.clueStatus, contactsDetail.clueStatus),
            tagList: [
              `${findValueByKey(this.dictList.clueSource, contactsDetail.clueSource)}`
            ]
          };
          // 线索信息
          this.$refs.clueInfo.getData(res.data.contactsDetail);
          // 跟进信息
          this.trackInfoDetailList = res.data.trackInfoDetailList;
          this.trackInfoDetailList.forEach(item => {
            item.trackType = findValueByKey(this.dictList.trackType, item.trackType);
          });
          // 负责销售
          this.sellUser = res.data.sellUserInfoDetail;
          if (this.$refs.salesInfo) this.$refs.salesInfo.getData(res.data.sellUserInfoDetail);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    getTabList() {
      if (this.$route.params.type === '1') {
        this.tabList.push({label: '负责销售', value: 2});
      }
    },
    toAddFollow() {
      this.$router.push(
        {
          name: 'clue-follow',
          params: {
            clueId: this.$route.params.clueId,
            clueName: this.contactsDetail.title
          }
        }
      );
    }
  },
  components: {
    tabComposite,
    custHeader,
    contactsInfo
  },
  mounted() {
    // this.getTabList();
    // this.getDetail();
  }
};
</script>

<style  lang="scss"  scoped>
@import '@/assets/css/components/btn-bottom.scss';
.header{
  width:100%;
  height:168px;
}
</style>
